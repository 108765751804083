
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import Page from './Page.vue';
import Alert from '../components/ui/Alert.vue';
import SurveySvg from '../components/svg/SurveySvg.vue';
import MultiStepForm from '../components/forms/MultiStepForm.vue';
import { MultiStepFormMixin, VuetifyMixin, MyCoachMixin, BAIconsMixin } from '../mixins';
import CourseCreateCourseInfo from '../components/courses/CourseCreateCourseInfo.vue';
import CourseCreateStaffInfo from '../components/courses/CourseCreateStaffInfo.vue';
import CourseCreateStudentList from '../components/courses/CourseCreateStudentList.vue';
import { CourseModel, StudentModel } from '../models/course';
import { StaffMember } from '../models/team/StaffMember';
import { CourseType } from '@/../types/enums';
import { CourseCreateFormValue, CourseCreateOptions } from '@/../types/interfaces';

import { courseCreateStore, coursesStore } from '../store';
import { getNextYear, getDateInNDays } from '@/helpers';

@Component({
	components: {
		Alert,
		MultiStepForm,
		Page,
		SurveySvg,
		CourseCreateCourseInfo,
		CourseCreateStaffInfo,
		CourseCreateStudentList,
	},
})
export default class CourseCreateForm extends Mixins(MultiStepFormMixin, MyCoachMixin, VuetifyMixin, BAIconsMixin){

	newCourseId: string = "";
	formComplete: boolean = false;
	get Loading(): boolean{
		return courseCreateStore.createCourseLoading || coursesStore.loadCourseLoading;
	}
	steps = 3;
	currentStep = 1;

	get CurrentStepValid(): boolean {
		const step = this.courseCreateForm[this.CurrentStepKey];
		return step ? step.valid : true;
	}

	get IsEdit(): boolean{
		return this.editCourseId !== undefined;
	}
	@Prop({ default: undefined }) editCourseId?: string;
	@Watch('editCourseId') async checkEditCourse(): Promise<void>{
		if(this.editCourseId !== undefined){
			await this.setupEditCourse(this.editCourseId);
		}else{
			this.resetForm();
		}
	}
	async mounted(): Promise<void>{
		this.checkEditCourse();
	}

	async setupEditCourse(courseId: string): Promise<void>{
		const editCourse = await coursesStore.getCourseById(courseId);
		this.courseCreateForm.step1.name = editCourse.name;
		this.courseCreateForm.step1.details = editCourse.details;
		this.courseCreateForm.step1.courseTemplate = editCourse;
		this.courseCreateForm.step1.startDate = editCourse.startDate;
		this.courseCreateForm.step1.endDate = editCourse.endDate;
		this.courseCreateForm.step2.staffMembers = editCourse.staffMembers;
		this.courseCreateForm.step3.students = editCourse.students;
	}

	resetForm(): void{
		this.courseCreateForm = this.getEmptyForm();
		this.currentStep = 1;
	}
	getEmptyForm = (): CourseCreateFormValue<CourseModel, StaffMember, StudentModel> => ({
		step1: {
			valid: false,
			name: "",
			details: "",
			courseTemplate: null,
			startDate: getDateInNDays(0),
			endDate: getNextYear(),
		},
		step2: {
			valid: true,
			staffMembers: [],
			sendInstructorInvitations: true,
		},
		step3: {
			valid: false,
			students: [],
			sendStudentInvitations: true,
		},
	});
	courseCreateForm: CourseCreateFormValue<CourseModel, StaffMember, StudentModel> = this.getEmptyForm();

	async finish(): Promise<void>{
		const isEdit = this.editCourseId !== undefined;
		const course = new CourseModel().load(this.courseCreateForm.step1.courseTemplate.copyAsTemplate());
		course.type = CourseType.Standard;
		course.name = this.courseCreateForm.step1.name;
		course.details = this.courseCreateForm.step1.details;
		course.startDate = new Date(this.courseCreateForm.step1.startDate);
		course.endDate = new Date(this.courseCreateForm.step1.endDate);

		course.staffMembers = this.courseCreateForm.step2.staffMembers;
	
		course.studentRefs = this.courseCreateForm.step3.students.map(s => {
			if(isEdit){
				return new StudentModel().load({...s, courseId: this.editCourseId});
			}
			return new StudentModel().load(s)
		});


		const opts: CourseCreateOptions = {
			inviteStudents: this.courseCreateForm.step3.sendStudentInvitations,
			inviteInstructors: this.courseCreateForm.step2.sendInstructorInvitations,
			sourceCoachId: this.MyCoachId,
		};

		if(!isEdit){
			console.groupCollapsed(`CourseCreate`);
			console.log("Create Course", { courseCreateForm: this.courseCreateForm });
			courseCreateStore.createCourse({ course, opts}).then((newCourse: CourseModel) => {
				console.log("New Course:", newCourse);
				console.groupEnd();
				this.formComplete = true;
				this.newCourseId = newCourse.id;
			});
		}else{
			console.groupCollapsed(`EditCourse`);
			course.id = this.editCourseId;
			courseCreateStore.patchCourse({ course, opts}).then((editedCourse: CourseModel) => {
				console.log("Edited Course Course:", editedCourse);
				console.groupEnd();
				this.formComplete = true;
				this.newCourseId = editedCourse.id;
			});
		}
	}
	cancel(): void{
		this.goToDashboard();
	}

	viewCourseProfile(): void{
		this.$router.push(`/i/course/${this.newCourseId}`);
	}
	goToDashboard(): void{
		this.$router.push('/');
	}
}
