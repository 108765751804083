
import { Component, Prop, Mixins } from 'vue-property-decorator';

import { AvailableProgramTypes, FormRulesMixin, CourseTemplatesMixin, BAIconsMixin } from '../../mixins';
import DatePicker from '../forms/DatePicker.vue';
import { CourseModel } from '../../models/course';
import { CourseCreateFormValue } from '@/../types/interfaces';
import TextEditor from '@/components/courses/TextEditor.vue';


@Component({
	components: { DatePicker, TextEditor },
})
export default class CourseCreateCourseInfo extends Mixins(AvailableProgramTypes, CourseTemplatesMixin, FormRulesMixin, BAIconsMixin){

	@Prop({ default: () => ({
		valid: false,
		name: "",
		courseTemplate: null,
		startDate: null,
		endDate: null,
		details: "",
	})}) value: CourseCreateFormValue<CourseModel>['step1'];
	input(){
		this.$emit('input', this.value);
	}

	$refs:{
		form: HTMLFormElement;
	}

	async submit($event: Event) {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.value.valid === false) return;
		this.$emit('submit', this.value);
	}

}
