
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '../../mixins';
import { courseDashboardStore } from '../../store';
import { AssignmentModel, CourseModel, StudentModel } from '../../models/course';
import AssignmentChip from '../ui/AssignmentChip.vue'
import CourseHeader from './CourseHeader.vue'
import CourseStudentList from './CourseStudentList.vue'
import SortIcon from '../ui/SortIcon.vue'
import UserChip from '../ui/UserChip.vue'
import { courseApi } from '@/api/CourseApi'

@Component({
	components: {
		AssignmentChip,
		CourseStudentList,
		CourseHeader,
		SortIcon,
		UserChip,
	}
})
export default class InstructorCourseDashboard extends Mixins(VuetifyMixin, BAIconsMixin){
	selectAll: boolean = true;
	assignToStudents: boolean = false;
	targetAssignment: AssignmentModel = null;
	showDeleteDialog: boolean = false;

	async created(): Promise<void> {
		await this.loadCourseFromUrl();
	}

	@Watch('$route.params.courseId') updateTeamId(): void{
		this.loadCourseFromUrl();
	}
	async loadCourseFromUrl(): Promise<void> {
		await courseDashboardStore.loadCourse(this.$route.params.courseId);
	}

	async sendAssignmentReminder({assignment,student}: {assignment: AssignmentModel, student: StudentModel}): Promise<void> {
		await courseApi.sendAssignmentReminder({
			courseId: this.$route.params.courseId,
			studentId: student.id,
			assignmentId: assignment.id,
		});
	}

	get PageLoading(): boolean {
		return !courseDashboardStore.courseInitialized || courseDashboardStore.courseLoading;
	}
	get course(): CourseModel {
		return courseDashboardStore.course;
	}
	
	enableAssign(): void {
		this.$router.push({ name: 'NewAssignment' });
	}

	createAssignmentFromTemplate(template: AssignmentModel): void{
		this.$router.push({
			name: 'NewAssignment',
			params:{
				/** This works to pass a model through to the next page. It just doesn't persist on refresh which is fine for this case */
				template: template as any,
			}
		});
	}

	get StaffJoinUrl(): string | null{
		return courseDashboardStore.staffJoinUrl;
	}
	get StudentJoinUrl(): string | null{
		return courseDashboardStore.studentJoinUrl;
	}
}


