
import { Component, Prop, Mixins } from 'vue-property-decorator';
import FormAddArea from '../forms/FormAddArea.vue';
import { title } from '../../pipes/title.pipe';
import { FormRulesMixin, BAIconsMixin } from '../../mixins';
import { CourseStaffRoleValues } from '@/../types/enums';

@Component({
	components: { FormAddArea },
})
export default class CourseCreateStaffInfo extends Mixins(FormRulesMixin, BAIconsMixin){
	staffTypes = CourseStaffRoleValues.map(role => {
		return { label: title(role), value: role };
	})

	addStaffMember(){
		this.value.staffMembers.push(this.defaultStaffMember());
	}
	removeStaffMember(index: number){
		this.value.staffMembers.splice(index,1);
	}
	defaultStaffMember(){
		return {
			type: "",
			firstName: "",
			lastName: "",
			email: "",
		};
	}

	get ShowStaffMembers(){
		return this.value.staffMembers.length > 0;
	}

	@Prop({
		default: () => ({
			valid: false,
			staffMembers: [],
			sendInstructorInvitations: true, 
		})
	}) value;
	input(){
		this.$emit('input', this.value);
	}

	$refs:{
		form: HTMLFormElement;
	}

	async submit($event: Event) {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.value.valid === false) return;
		this.$emit('submit', this.value);
	}
}
