
import { Component, Prop, Mixins } from "vue-property-decorator";
import { 
	mdiCloseBox, 
	mdiMinusBox, 
	mdiCheckboxBlankOutline
} from "@mdi/js";

import { MyInstructorCoursesMixin, FormRulesMixin, AssignmentMixin, BAIconsMixin } from "@/mixins";
import AssignmentChip from "@/components/ui/AssignmentChip.vue";
import UserChip from "@/components/ui/UserChip.vue";
import DatePicker from "@/components/forms/DatePicker.vue";
import AttachmentsArea from '@/components/forms/AttachmentsArea.vue';
import TextEditor from '@/components/courses/TextEditor.vue';

import Page from "./Page.vue";
import { AssignmentModel, StudentModel } from "@/models/course";
import { coursesStore } from '@/store';
import { getDateInNDays } from '@/helpers';
import { courseApi } from '@/api/CourseApi';

@Component({
	components: {
		AssignmentChip,
		AttachmentsArea,
		TextEditor,
		DatePicker,
		Page,
		UserChip,
	}
})
export default class NewAssignment extends Mixins(AssignmentMixin, MyInstructorCoursesMixin, FormRulesMixin, BAIconsMixin) {
	mdiCloseBox = mdiCloseBox;
	mdiCheckboxBlankOutline = mdiCheckboxBlankOutline;

	formLoading: boolean = false;

	get DocumentUploadAssignment(): AssignmentModel{
		return AssignmentModel.getDocumentUploadAssignment();
	}

	get NoAssigmentType(): boolean{
		return !this.value.useVideoReview && ! this.value.useDocumentUpload;
	}

	@Prop({ default: null }) template: AssignmentModel | null;
	@Prop() value: {
		valid: false,
		assignmentTemplate: AssignmentModel | null,
		selectedStudents: StudentModel[],
		name: string,
		description: string,
		details: string,
		dueDate: Date | null,
		useVideoReview: boolean,
		useDocumentUpload: boolean,
		calendlyUrl: string | null,
	} = {
		valid: false,
		assignmentTemplate: null,
		selectedStudents: [],
		name: "",
		description: "",
		details: "",
		dueDate: getDateInNDays(7),
		useVideoReview: true,
		useDocumentUpload: false,
		calendlyUrl: null,
	};
	input(): void {
		this.$emit("input", this.value);
	}

	$refs: {
		form: HTMLFormElement;
		attachmentsArea: AttachmentsArea;
	};

	goBackToCourse(): void{
		window.history.back();
	}

	isSelected(item: StudentModel): boolean{
		return this.value.selectedStudents.includes(item);
	}

	async created(): Promise<void>{
		this.setupForm();
		await this.initDocumentUploadId();
	}

	setupForm(): void{
		if(this.template === null) return;
		this.value.assignmentTemplate = null;
		if(this.template.rubric){
			/**
			 * assignmentTemplate here is the placeholder in the form for a rubric. The Course curriculum is a set of Assignments which hold the rubric templates.
			 * 
			 * If our selected template includes a rubric - then select the template in the curriculum with a matching rubric
			 */
			this.value.assignmentTemplate = this.InstructorCurrentCourse.curriculum.find(a => a.rubric.id === this.template.rubric.id) ?? null;
		}
		this.value.name = this.template.name;
		this.value.description = this.template.description;
		this.value.details = this.template.details;
		this.value.useVideoReview = this.template.useVideoReview;
		this.value.useDocumentUpload = this.template.useDocumentUpload;
	}

	documentUploadId: string | null = null;
	get FileUploadParentId(): string{
		return this.documentUploadId;
	}
	async initDocumentUploadId(): Promise<void>{
		if(this.template === null){
			const { uuid } = await courseApi.uuid();
			this.documentUploadId = uuid;
		}else{
			this.documentUploadId = this.template.documentUploadId;
		}
	}
	get FileUploadApiPrefix(): string{
		return `course/${this.CurrentCourseId}/student/assignment/documents/${this.documentUploadId}/files`;
	}

	async submit($event: Event): Promise<void> {
		this.formLoading = true;
		$event.preventDefault();
		this.$refs.form.validate();
		if (this.value.valid === false) return;

		const newAssignment = this.value.useVideoReview === true ?
			this.value.assignmentTemplate.copyAsTemplate() :
			AssignmentModel.getDocumentUploadAssignment();

		if(this.value.name){
			newAssignment.name = this.value.name;
		}else if(this.value.useVideoReview && this.value.assignmentTemplate){
			newAssignment.name = this.value.assignmentTemplate.name;
		}else{
			newAssignment.name = 'Assignment';
		}

		newAssignment.active = true;
		newAssignment.documentUploadId = this.documentUploadId;
		newAssignment.useDocumentUpload = this.value.useDocumentUpload;
		newAssignment.useVideoReview = this.value.useVideoReview;
		newAssignment.description = this.value.description;
		newAssignment.details = this.value.details;
		newAssignment.calendlyUrl = this.value.calendlyUrl;
		if(this.template !== null){
			newAssignment.color = this.template.color;
			newAssignment.backgroundColor = this.template.backgroundColor;
		}
		if(newAssignment.useDocumentUpload === true){
			await this.$refs.attachmentsArea.uploadAllFiles();
		}
		this.$emit("submit", this.value);
		try{

			await coursesStore.createAssignment({
				courseId: this.InstructorCurrentCourseId,
				assignment: newAssignment,
				studentIds: this.value.selectedStudents.map(s => s.id),
			});
		}finally{
			this.formLoading = false;
		}
		this.goBackToCourse();
	}
	/** Rubric is required if we're using video review */
	get RubricRules(): Array<any> | undefined{
		if(this.value.useVideoReview){
			return this.rulesRequired;
		}
		return undefined;
	}
	get CourseAssignmentTemplates(): AssignmentModel[]{
		if(this.InstructorCurrentCourse === undefined) return [];
		return this.InstructorCurrentCourse.curriculum;
	}
	get StudentList(){
		if(this.InstructorCurrentCourse === undefined) return [];
		return this.InstructorCurrentCourse.students;
	}
	get Rubric(){
		if(this.value.assignmentTemplate === null) return null;
		return this.value.assignmentTemplate.rubric;
	}

	get AllStudentsSelected(): boolean{
		return this.value.selectedStudents.length === this.StudentList.length;
	}
	get SomeStudentsSelected(): boolean{
		return this.value.selectedStudents.length > 0 && !this.AllStudentsSelected;
	}
	get StudentSelectAllIcon (): { icon: string, color?: string } {
		if (this.AllStudentsSelected) return { icon: mdiCloseBox, color: 'red'};
		if (this.SomeStudentsSelected) return { icon: mdiMinusBox, color: 'red'};
		return { icon: mdiCheckboxBlankOutline };
	}
	get NoStudentsSelected(): boolean{
		return !this.AllStudentsSelected && !this.SomeStudentsSelected;
	}
	selectAllStudents(): void{
		this.$nextTick(() => {
			if (this.AllStudentsSelected) {
				this.value.selectedStudents = [];
			} else {
				this.value.selectedStudents = this.StudentList.slice();
			}
		})
	}
}
